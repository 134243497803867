// 设备型号管理
<template>
    <div class="system-detail"
         :class="{baseBg: isPage}"
         v-loading="isloading">
        <backUp v-if="isPage"></backUp>
        <el-row>
            <div class="cardBox"
                 v-if="deviceModel.typeInfoList.length > 0">
                <div class="cardTitle">设备信息</div>
                <el-row v-for="(item,index) in deviceModel.typeInfoList"
                        :key="index">
                    <p v-if="item.empTypeFieldName"><span class="label">{{item.empTypeFieldName || ''}}：</span><span class="value">{{item.empTypeValue || '无'}}</span></p>
                </el-row>
            </div>
            <div class="emptyBg"
                 style="height: 400px"
                 v-else></div>

        </el-row>

    </div>
</template>

<script>
import backUp from "@/components/backUp";
export default {
    name: "deviceModelDetail",
    props: {
        deviceDetails: {
            type: Object,
            default: null,
        },
        deviceModel: {
            type: Object,
            default() {
                return {
                    empType: "",
                    provider: "",
                    empTypeModel: "",
                    typeInfoList: [],
                };
            },
        },
    },
    components: {
        backUp,
    },
    data() {
        return {
            // deviceTypeName: "",
            equData: {},
            isloading: false,
            isPage: false, // 是作为页面还是组件
        };
    },

    created() {},

    mounted() {
        // this.deviceTypeName = this.$route.query.deviceType;
        if (this.$route.meta.title === "设备型号详情") {
            this.isPage = true;
            this.deviceDetails = JSON.parse(this.$route.query.data);
            this.deviceModel = JSON.parse(this.$route.query.data);
            this.deviceModel.typeInfoList =
                this.deviceModel.typeModelInfoListVos;
        }
    },

    methods: {},

    computed: {},

    watch: {},
};
</script>

<style lang='scss' scoped>
</style>
